import IconNoProfileImage from "../assets/icons/no-profile-picture.svg?string";
import IconTableUser from "../assets/icons/table-user.svg";
import IconTableEmail from "../assets/icons/table-email.svg";
//
import { Table } from "../components/table";
import type { ApiResponse } from "../apis/_model";
import { EmptyTable } from "../components/empty-table";
import type { _SolidState } from ":shared/utils/state-manager";
import { removeTeamMember } from "../apis/remove-team-member";
import { global } from ":global";
export function TableTeamMembers(props: { teams: _SolidState.State<ApiResponse.GetTeams> }) {
  return (
    <Table
      rows={props.teams.value.team_members}
      fallback={<EmptyTable msg="You do not have any member in your team currently " />}
      classes={{
        desktop: {
          columnContainer: "bg#bg-default  font-semibold text-0.77rem rounded-t-6 ",
          singleColumn: "h-3.5rem ",
          singleRowContainer:
            " overflow-y-hidden h-3rem text-0.75rem min-h-5rem border-b-2 border-solid border-gray-100",
          singleRow: "",
        },
        phone: {
          cardsContainer: "space-y-5 !h-full",
          singleCardContainer:
            "shadow-md w-full !min-h-10rem py-2 border-2 border#bg-default border-solid rounded-xl !justify-between",
          allCardsRows: "!w-90% !justify-betwen py-1.5",
          singleCardHeader: "!text-0.5rem text-gray w-full",
          singleCardValue: "!w-fit !text-0.45rem",
        },
      }}
      columns={{
        User: {
          desktop: {
            // width: "40%",
            icon: <IconTableUser class="w-24px" />,
            class: "pl-8 flex-1",
          },
          phone: {
            cardHeaderClass: "!hidden !max-w-0 ",
            class: "border-b-2 border-solid border-gray-100",
          },
          key: (r, helper) => {
            return (
              <div class="flex flex-row space-x-2">
                <img
                  class="w-80px h-80px <md:(w-50px h-50px) rounded-full object-cover"
                  src={r.image ?? IconNoProfileImage}
                  alt={"profile_image"}
                />
                <p class="flex flex-col ">
                  <span class="font-bold text-0.8rem <md:text-0.6rem">{`${r.name ?? "Unknown Name"}`}</span>
                  <span>{`${r.reference ?? "#000000"}`}</span>
                </p>
              </div>
            );
          },
        },
        Role: {
          desktop: {
            // width: "10%",
            class: "flex-[0.2]",
          },
          key: (r) => (
            <span
              class={`capitalize rounded-xl px-2 py-1 text-0.64rem  h-fit <md:(text-0.4rem !self-end)
            ${r.role === "leader" ? "bg-orange-100 text-orange-400" : "bg-[#85B1AD40] text-[#85B1AD]"}`}
            >
              {r.role}
            </span>
          ),
        },
        "Email Address": {
          desktop: {
            icon: <IconTableEmail class="w-24px" />,
            width: "20%",
          },
          phone: {},
          key: "email",
        },
        Action: {
          desktop: {
            width: "15%",
            headerClass: "-ml-1",
          },
          key: (r) => {
            if (
              props.teams.value.role === "member" ||
              r.role === "leader"
              // props.teams.value.team_members?.length <= 2
            ) {
              return null;
            }
            return (
              <span
                class="h-fit text-red underline cursor-pointer !w-full whitespace-nowrap"
                onclick={() => {
                  global.store.actions.popop.pushConfirmDialog({
                    type: "warn",
                    message: `Are you sure you want to request member ${r.name} to leave your team?!`,
                    events: {
                      async onConfirmed() {
                        return removeTeamMember({ id: r.id }).then(() => {
                          global.store.actions.alert.pushToast({
                            type: "success",
                            message: `Member ${r.name} has recieved removal request!`,
                          });
                          // props.teams.set((s) => {
                          //   const team_members = s.team_members.filter((x) => x.id !== r.id);
                          //   return { ...s, team_members };
                          // });
                        });
                      },
                    },
                  });
                }}
              >
                Request Removal
              </span>
            );
          },
        },
      }}
    />
  );
}
